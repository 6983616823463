import { FC, useMemo } from 'react';
import routes from 'constants/routes';
import Link from 'components/shared/Link';
import VerticalNavigationMenuOption from 'components/navigation/verticalNavigation/VerticalNavigationMenuOption';
import InAppNotificationsIcon from 'components/in-app-notifications/Icon';
import useInAppNotificationsCountQuery from 'app/modules/inAppNotifications/hooks/useInAppNotificationsCountQuery';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useTranslation } from 'react-i18next';

type Props = {};

const Notifications: FC<Props> = () => {
  const { data } = useInAppNotificationsCountQuery();
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <Link
        to={routes.IN_APP_NOTIFICATIONS}
        data-name="navbar_notifications"
        isRouterLink
      >
        <VerticalMenuItemWrapper>
          <VerticalNavigationMenuOption
            label={t('navigation.userSubActions.notifications')}
            icon={
              <InAppNotificationsIcon notificationsCount={data?.count ?? 0} />
            }
          />
        </VerticalMenuItemWrapper>
      </Link>
    );
  }, [data?.count, t]);
};

export default Notifications;
